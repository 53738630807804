.select-list{
    display: flex;
    justify-content: space-between;
    margin: 40px 0px;
    padding:30px 30px;
    background-color: #FAFAFA;
    border-radius: 20px;
    text-decoration: none;
    gap: 50px;
    flex-direction: column;
}



.image-side{
    width: 100%;
    border-radius: 15px;
    height: 100%;
    overflow:hidden
}
/* the first flex item on the */
.title{
    font-size: 1.6rem;
    font-weight: 500;
    color: #464646;
    line-height: 2.0rem;

}

.genre{
    line-height: 4rem;
    color: #525252;
}

.brief{
    line-height: 1.7rem;
    color: #525252;
}

.cs-btn{
    display: inline-block;
    border-radius: 50px;
    margin-top:20px;
}

.cs-btn button{
    display: flex;
    align-items:center ;
    padding:10px 20px;
    align-items: center; 
    text-decoration: none;
    border-radius: 25px;
    border: 1px solid #DEDEDE;
    font-weight: 500;
    font-size: 14px;
    color: #525252;
    cursor: pointer;


}
.image-side img{
    width: 100%;
    object-fit: contain;
}

.cs-btn button:hover{
    background-color: #525252;
    color: white;
    transition: 1s;
 }

@media screen and (max-width:600px) {
    .select-list{
        flex-direction: column-reverse;
        width: 100%;
    }

    .text-side{
        margin: 20px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .title{
        min-width: 100%;
        font-size: 1.5rem;
        text-align: left;
    }

    .genre{
        font-size: 1rem;
        text-align: center;
    }

     .brief{
        width: 100%;
        text-align: justify;
        text-align: left;
     }

    

}

@media screen and (max-width:768px) {
    .select-list{
        flex-direction: column-reverse;
        width: 100%;
    }

    .text-side{
        margin: 20px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        
    }
    .title{
        min-width: 100%;
        font-size: 1.5rem;
    }

    .genre{
        font-size: 1rem;
    }

     .brief{
        width: 100%;
     }

    

}

@media screen and (max-width:1024px) {
    .text-side{
        width: 100%;
    }
}