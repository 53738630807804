
.singleimage-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-bottom: 10vh;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .singlesize-expandable {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    object-fit: contain;
  }
  

  /* ImageModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background: none;
    border: none;
  }
  
  .modal-image {
    max-width: 90vw;
    max-height: 90vh;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  