.thts-pg{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 100px 0 0;
    padding: 50px 0;
}

.thts-frame{
    display: flex;
    flex-direction: column; 
    align-items: center;
    width: 60%;
}

.thts-frame h2{
    font-size: 5rem;
    font-weight: 800;
    color: #464646;
    margin-bottom: 10px;
}

.thts-link{
    text-decoration: none;
}


.thts-card{
    display: flex;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 12px;
    gap: 20px;
    margin: 10px 0;
    text-decoration: none;
}




.thts-title{
    font-size: 1.1rem;
    font-weight: 500;
    color: #464646;
    line-height: 1.4rem;
    margin-bottom: 4px;
}

.thts-pre{
    text-decoration: none;
    color: #888888;
    margin-bottom: 4px;
}



.thts-blog{
    display: flex;
    flex-direction: column;
    width: 50%; 
}

.thts-coverimg{
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border-radius: 20px;
    margin: 20px 0;
}

.thts-Head{
    font-size: 1.6rem;
    font-weight: 600;
    color: #464646;
    line-height: 2rem;
    margin-bottom: 10px;
}

.thts-date{
    text-decoration: none;
    color: #888888;
    font-weight: 500;
}

.thts-header{
    margin: 100px 0 30px 0;
}


/* ThoughtStyles.css */
.thts-content h1 {
    font-size: 2em;
    margin-bottom: 1em;
  }
  
  .thts-content h2 {
    font-size: 1.75em;
    margin-bottom: 0.75em;
  }
  
  .thts-content h3 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .thts-content p {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 1em;
    color: #525252;
  }
  
  .thts-content blockquote {
    font-size: 1.25em;
    margin: 1em 0;
    padding-left: 1em;
    border-left: 4px solid #ccc;
  }
  
  .thts-content img {
    max-width: 100%;
    height: auto;
    margin: 1em 0;
  }
  
  .thts-content strong {
    font-weight: bold;
  }
  
  .thts-content em {
    font-style: italic;
  }
  
  .thts-content a {
    color: #0070f3;
    text-decoration: underline;
  }
  

  @media screen and (max-width:600px) {
    .thts-pg{
        padding:100px  20px;
    }

    .two-columns{
        flex-direction: column;
    }

    .thts-frame h2{
        font-size: 2rem;
        font-weight: 800;
        color: #464646;
        margin-bottom: 10px;
    }

    /* .remv{
        display: none;
    } */

    
    .thts-blog{
        width: 100%;
    }

}

@media screen and (min-width:601px) and (max-width:1024px) {
    .cs-body{
        padding: 100px 50px;
    }
}