.p-banner{
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;

}

.spin-shine{
    transition: 2s;
    animation: rotate infinite linear 3s;
}
/* animation to rotate the sunray */
@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

.p-banner p{
    font-size: 64px;
    display: flex;
    align-items: center;
}

.spin-shine {
    height: 70%;
}
.grey{
    color: #7A7A7A;
    display: flex;
    align-items: center;
    font-size: 64px;
    font-weight: 500;
}
.grey2{
    display: flex;
    align-items: center;
    font-size: 64px;
    font-weight: 500;
    padding: 0px 10px; 
    }

/* the list of projects Built */
.Proj{
    padding: 50px 100px;
    height: 100%;
}


@media screen and (max-width:600px) {
    .p-banner{
        height: 50vh;
    }
    
    .Proj{
        padding: 20px;
    }
    .p-banner p{
        font-size: 32px;
    }

    .grey{
        display: none;   
    }

    .grey2{
    display: flex;
    align-items: center;
    font-size: 64px;
    font-weight: 500;
    color: #7A7A7A;   
    }


}



@media screen and (min-width:601px) and (max-width:1024px) {
    .Proj{
        padding: 0px 50px;
    }
}


