
.banner{
    position: relative;
    display: flex;
    background-color: #FAFAFA;
    justify-content: center;
    align-items: center;
    padding: 16% 20% 6% 20%;

}

.darkbg{
    background-color:#121212 ;
    color: white !important;
}

.profile{
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.banner-ctas{
    margin: 20px 0px;
    display: flex;
    align-items: center;

}

.otw-ctas{
        display: flex;
        border-radius: 50px;
        align-items: center;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
        font-size: 16px;
        font-weight: 600;
        margin: 20px 0px 30px 0px;
        gap: 5px;
        padding: 10px 15px;
        color: #ffffff;
        width: 260px;
        background-color: #1072E5;

}

.otw-ctas:hover{
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
}

.otw-btnk{
    display: flex;
    padding: 10px 15px;
    gap: 5px;
}



.otw-div{
    color: #f3f3f3;
}

.otw-btn a{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #ffffff;
    text-decoration: none;
}

.cr-values{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0px;
}

.cr-values1{
    font-weight: 700;
    font-size: 2rem;
    color: #3b3b3b;
}

.cr-values2{
    font-weight: 700;
    font-size: 2rem;
    color: #3b3b3b;
}

.value{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 60px;
    

    width: 60vw;
}

.value-each{
    flex: 1;
    padding: 10px;
    background-color: #C7C7C7;
    padding: 16px;
    background-color: #1e1e1e;
    margin-bottom: 10px;
    border-radius: 8px;
}

.value-head{
    font-weight: 700;
    font-size: 1.2rem;
    color: #C7C7C7;
    margin-bottom: 5px;
}

.seeal{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}


.banner-column{
    display: flex;
    gap: 50px;
    padding: 20px 0px;
    height: auto;
    flex-direction: column;
    
}

.banner-head{
    font-weight: 500;
    color: #525252;
    width: 100%;
    font-size: 2.2rem;
}

.banner-col{
    width: 60%;
}

.banner-para{
    margin-bottom: 20px;
    line-height: 1.7rem;
    width: 100%;
    font-size: 18px;
    color: #525252;
}

.home{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* breakpoint 425px */
@media screen and (max-width:600px) {
    .banner{
        padding: 160px 20px 50px 20px;
        align-items: flex-end;
    }
    
    .banner-column{
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .banner-head{
        text-align: left;
        width: 100%;
        padding-top: 50px;
    }

    .banner-col{
        display: flex;
        flex-direction: column;
        align-items: left;
        width: 100%;

    }
    .banner-para{
        text-align: center;
        font-size: 16px;
    }


    .profile{
        display: flex;
        flex-direction: column;
    }

    .profile-name, .metext{
        width: 100%;
        text-align: left;
    }

    .profile-name p{
        font-size: 32px;
    }

    .Pro-name2{
        font-size: 16px;
    }

    .raystory{
        width: 100%;
    }

    .banner-para {
        text-align: left;
    }

    .banner-column {
        flex-direction: column;
    }

    .banner-col {
        width: 100%;
    }

}



@media screen and (min-width:601px) and (max-width:768px) {

    .banner{
        padding: 160px 50px 50px 50px;
    }
    .banner-column{
        display: flex;
        flex-direction: column;
        align-items: left;
        width: 100%;

    }
    .Pro-name2{
        font-size: 24px;
        text-align: center;
    }

    .raystory{
        width: 100%;
    }    

    .banner-col{
        width: 100%;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .banner-column{
        flex-direction: column;
    }
    .banner{
        padding: 160px 50px 50px 50px;
    }

    .banner-col{
        width: 100%;
    }
    
}

