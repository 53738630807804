.caseTalks{
    display: flex;
    gap: 20px;
    padding: 50px 0px;
}

.h1-eads{
    width: 100%;
    color:#525252;
}

.p-bdys{
    width: 100%;
    line-height: 2rem;
    color: #525252;
}

.hi-light{
    display: flex;
    gap: 20px;
    margin-top: 50px;
}

.barSide{
    width: 10px ;
    background-color: #525252 ;
    border-radius: 25px;
}

.subs{
    font-size:20px;
    font-weight: 500;
    text-align: center;
    margin: 30px 0px;
    padding: 20px;
    background: #f4f4f4;
}

.sub-head{
    font-size:20px;
    font-weight: 500;
    color: #525252;
    width: 100%;
}
.subBody{
    width: 100%;
}

.prags-case p{
    margin-bottom: 20px;
}