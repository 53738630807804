.more{
    padding: 50px 100px;
    font-family:"DM Sans", sans-serif ;
    background-color: white;
}

.more-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:40px 0px;
    border-bottom: 1px solid #DEDEDE;
}

.more-ray{
    display: flex;
    align-items: center;
}

.more-ray p{
    font-weight: 600;
}
.sunray{
    margin-right:10px;
    transition: 2s;
    animation: rotate infinite ease-in-out 3s;
}
/* animation to rotate the sunray */
@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

/* the list of more projects */
.morelist{
    display: flex;
    padding: 40px 0px;
    gap: 10px;
}

.more-items{
    width: 35%;
    margin:0px 15px ;
}

.mre-img{
    width: 100%;

}

.mre-img img{
    width: 100%;
    object-fit: fill;
}

.ti-tle{
    font-size: 1.3rem;
    font-weight: 600;
    color: #464646;
    margin: 10px 0px;
    width: 100%;
}

.bri-ef{
    font-size: 14px;
}

@media screen and (max-width:600px){
    .more{
        padding: 20px;
    }

    .more-items{
        width:auto;
    }

    .ti-tle{
        font-size: 1rem;
    }

    .morelist{
        flex-wrap: wrap;
        gap: 30px;
    }

    .more-items{
        width: 100%;
        margin:0px 15px ;
    }
    


}