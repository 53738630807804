.Base{
    position: relative;
}

.navigation{
    padding: 10px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin: 0px;
    
    position: fixed;
    top:10px;
    left: 50%;
    transform: translateX(-50%);
    
    box-shadow: 0px 0px 10px 0px #08080814;
    z-index: 10000;
    background-color: white;
}

.navigation.hidden {
    transform: translate(-50%, -150%); /* Move upwards while keeping horizontal center */
    opacity: 0; /* Optional: Add opacity to fade out */
}

.navigation.scrolled {
    border-bottom: 0.5px solid #EDEDED; /* Add white border on scroll */
}

.navItem a:hover .rotate-icon, 
a:hover .rotate-icon {
    transform: rotate(20deg); /* Rotate the icon 20 degrees */
    transition: transform 0.3s ease-in-out; /* Smooth transition effect */
}

.med-nav{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

}

.me-logo{
    height: 40px;
    width: 45px;
    border-radius: 50%;
    overflow: hidden;
}

.me-logo :hover{
    background-color: #d9d9d9cc;
}

.me-logo img{
    object-fit: contain;
    height: 100%;
    width: 100%;
}

/* //menu button for mobile */



.mnbtn{
    display: flex;
    flex-direction: column;
    gap: 3px;

    display: none;
    cursor: pointer;
}

.mnbtn p{
    font-weight: bold;

}
.logo{
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: bottom;
    margin: 0px 5px;
    height: auto;
    align-content: bottom;
}

.logo img{
    object-fit: fill;
}

.setCenter{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.setCenter li{
    margin: 0px 5px;
    
}
.setCenter li a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    font-weight: 600;
    padding:10px 10px;
    font-size:18px;
    border-radius: 50px;
    transition: .5s;

}
.setCenter li a:hover{
    background-color: #d9d9d962;

}


.see{
    position: fixed;
    top:55px;
    right: 6%;
    background-color: white;
    border-radius: 50px;
    z-index: 10000;

    color: black;
    font-weight: 600;
    padding: 21px;
    box-shadow: 0px 0px 10px 0px #08080814;
    border-radius: 50px;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
}

.see :hover{
    background-color: #d9d9d9cc;
}

.active{
    background-color: rgba(255, 255, 255, 0);
}

/* the darkmode CSS */

.darknavigation{
    background-color: #121212;
    box-shadow: 1px 1px 5px 0px rgba(145, 145, 145, 0.34);
    /* border: 1px solid rgba(255, 255, 255, 0.382); */

}

.darkli a p {
    color: white ;
}
/* .darkText{
    color: white !important;
} */

.darkul{
    background-color: #121212 !important;
}

.modes{
    /* background-color: white; */
    background-color: #f6f6f6e5;

    padding:10px 10px;
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:768px)  {

    .med-nav{
        display: flex;
        align-items: center;
        width: 55%;
    }

    .mnbtn{
        display: flex;
    }
    .navigation{
        position: fixed;
        width: 90%;
        top: 20px;
        left: 5%;
        transform: translate(0);
        justify-content: space-between;

    }


    .setCenter{
        display: none;
    }

    .showNav{
        order: 3;
        position: absolute;
        top: 110%;
        width: 95%;
        list-style: none;

        background-color: white;
        border-radius: 25px;
        box-shadow: 1px 1px 5px 0px rgba(128, 128, 128, 0.34);
        /* border: 1px solid grey; */


    }

    

    .showNav li:hover{
        background-color: #d9d9d962;
        border-radius: 25px;
        border-radius:25px ;
    }

    .showNav li a{
        color: black;
        text-decoration: none;
        display: flex;
        padding: 20px 20px;
        font-weight: 450;

    }
    

    
    .see{
        position: fixed;
        font-size: 14px;
        top: 90%;
    }
}
@media screen and (min-width:769px) and (max-width:1024px) {
    .navigation{
        width: 60%;
        left:50%;
        transform: translate(-50%);
        justify-content: space-between;
        position: fixed;
    }

    .setCenter li a{
        font-size: 15px;
    }

    .see{
        position: fixed;
        font-size: 16px;
        right: 5px;
        top: 90%;
    }
}

