.story-frame{
    display: flex;
    flex-direction: column;
    width:65%;
}

.strys{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    gap: 0px 50px;
}

.lineHead{
    width: 50%;
    text-align: right;
    color: #464646;
    line-height: 1.7rem;
}

.st-ry{
    width: 100%;
}

.st-ry p{
    color: #525252;
    font-weight: 450;
    line-height: 1.7rem;
}

.prag{
    margin-bottom: 20px;
}

.st-ry ul p{
    margin-bottom:5px ;
}
.st-ry ul li{
    margin: 20px;
    color: #525252;
    line-height: 1.7rem;
}

@media screen and (max-width:600px) {
    .story-frame{
        width: 100%;
        gap: 20px;
        flex-direction: column;
        padding:0px 20px;
    }

    .strys{
        flex-direction: column;
        text-align: justify;
    }

    .lineHead{
        width: 100%;
        text-align: left;
        line-height: 3rem;


    }
}

@media screen and (min-width:601) and (max-width:1024px) {
    .story-frame{
        width: 80%;
    }
}
