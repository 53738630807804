.pod-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 60px;
}

.image-contnr {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
  border-radius: 10px;
}

.h1-title {
  margin-bottom: 25px;
  font-size: 2em;
  line-height: 120%;
  color: #1c1c1c;
}
.p-subtext {
  margin-bottom: 25px;
  color: #4b4b4b;
  font-size: 1em;
  line-height: 150%;
}

.primary-button {
  color: #1c1c1c;
  background-color: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 50px;
  padding: 10px 22px;
  font-size: 1em;
}

.button-link {
  text-decoration: none;
  color: inherit;
}

/* Container for the grid */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px 0;
}

.card {
  border: 1px solid #0000001a;
  border-radius: 10px;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  display: flex;
  box-shadow: 0px 0px 10px 0px #08080814;
}

.card:hover {
  cursor: url(../../src/Assets/images/podcastcursor.png), auto;
}
.host {
  align-items: center;
  display: inline-flex;
  gap: 5px;
  margin-bottom: 5px;
}

.host-text {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
}

.host-name {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
}

/* Image inside the card */
.card-image {
  border-radius: 2px;
  width: 100%;
  overflow: hidden;
}

/* Content inside the card */
.card-content {
  padding: 15px;
}

/* Styles for date and title */
.card-date {
  color: gray;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.card-title {
  font-size: 1rem;
  margin: 0;
  text-decoration: none;
  margin-bottom: 10px;
  color: #1c1c1c;
}

/* General page styling */
.podcast-page {
  margin-bottom: 60px;
}

/* Podcast title styling */
.podcast-title {
  margin-bottom: 25px;
  padding-bottom: 25px;
  font-size: 2rem;
  color: #1c1c1c;
}

/* Podcast image container */
.podcast-image-container {
  display: block;
  width: 100%;
  border-radius: 16px;
  object-position: center center;
  object-fit: cover;
  image-rendering: auto;
  margin-bottom: 30px;
}
.play-logo {
  width: 24px;
  height: 24px;
}

.play-button {
  color: #1c1c1c;
  background-color: #ffffff;
  border: 1px solid #1c1c1c;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 1em;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 10px;
}
/* Podcast image styling */
.podcast-image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center center;
  object-fit: cover;
  image-rendering: auto;
}

/* Date text in the overlay */
.podcast-date {
  font-size: 1rem;
  margin-bottom: 10px;
}

/* Buttons inside the overlay */
.button-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.spotify-button,
.apple-button {
  padding: 8px 12px;
  font-size: 0.9rem;
  color: white;
  background-color: #1db954; /* Spotify Green */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.apple-button {
  background-color: #a2aaad; /* Apple Gray */
}

.spotify-button:hover {
  background-color: #1ed760;
}

.apple-button:hover {
  background-color: #b1b6ba;
}

/* About section grid styling */
.about-grid {
  grid-column-gap: 70px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

/* About column styling */
.about-column {
}

.about-column h2 {
  margin-top: 0;
}

.thts-content h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

.thts-content h2 {
  font-size: 1.75em;
  margin-bottom: 0.75em;
}

.thts-content h3 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.thts-content p {
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 1em;
  color: #525252;
}

.thts-content blockquote {
  font-size: 1.25em;
  margin: 1em 0;
  padding-left: 1em;
  border-left: 4px solid #ccc;
}

.thts-content img {
  max-width: 100%;
  height: auto;
  margin: 1em 0;
}

.thts-content strong {
  font-weight: bold;
}

.thts-content em {
  font-style: italic;
}

.thts-content a {
  color: #0070f3;
  text-decoration: underline;
}
