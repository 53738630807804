.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
}

.foot-info{
    display: flex;
    justify-content: space-between;
}

.footLinks{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:18%;
}

.mainlinks, .ct-links{
    list-style: none;
    
}

.sect p{
    color: rgba(0, 0, 0, 0.42);
    font-size: 11px;
    font-weight: bold;
}



.footLinks ul li{
    margin: 20px 0px;
}

.footLinks ul li a{
    color: black;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;

    display: flex;
    
}

.owner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 50px 0px;

}

.copyright{
    font-weight: bold;
    font-size: 12px;
    display: flex;
    flex-direction: column;
}

.light{
    color: rgba(0, 0, 0, 0.42);
    font-weight: 500;
    line-height: 2rem;
}

.dn-t{
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.42);
    font-weight: 500;
    font-size: 11px;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width:600px) {
    .footer{
        padding: 20px;
    }

    .footLinks{
        width: 50%;
    }

    .dn-t{
        flex-direction: column;
    }
}