.work-edu{
    display: flex;
    width: 65%;
    gap: 0px 50px;
}

.titbloc{
    width: 50%;
    text-align: right;
  
}

.workstory-block{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bo-xe{
    border: 1px solid black;
    padding: 20px;
    border-radius: 20px;
    background-color: #F7F7F9;
    border: 1px solid #EBEBEB;
    margin-bottom: 20px;
    height: auto;
}

.ti-te{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.locate{
    line-height: 1.8rem;
}

.blck{
    color: #333333;
    font-family: 450;
}

.gry{
    color: #888888;
    font-size: 1rem;
}

.respo{
    margin-top: 20px;
}

.resp-ti{
    line-height: 2rem;
}

.prod-flex{
    display: flex;
    gap: 20px;
    margin: 30px 0px;
}

.prod-head{
    font-weight: 600;
    margin-bottom:20px;
}

.pro-body{
    color: #888888;
    font-size: 16px;
}

@media screen and (max-width:600px) {
    .work-edu{
        width: 100%;
        padding: 0px 20px;
        gap: 20px;
        flex-direction: column;
    }

    .titbloc{
        width: 100%;
        text-align: left;
    }

    .pro-body{
        font-size: 14px;
    }    
}

