.b-banner{
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;
}

.sides{
    display: flex;
    margin: 30px 0px;
}

.b-banner p{
    display: flex;
    align-items: center;
    font-size: 64px;
    font-weight: 500;
    color: #7A7A7A;
} 

.spinner{
    height: 70px;
    transition: 2s;
    animation: rotate infinite linear 3s;
}
/* animation to rotate the sunray */
@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

.seeBuild{
    display: flex;
    justify-content: space-between;
    padding: 50px 100px;
    gap:50px;
}

.btext-spc{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.bspc-img{
    width: 100%;
}

.bspc-hd{
    /* margin-bottom: 10px; */
    font-size: 2rem;
    font-weight: 500;
    color: #464646;
    width: 85%;
    line-height: 2.5rem;
}
.bspc-tx{
    line-height: 1.7rem;
    margin: bottom;
    color: #525252;
}

.btext-spc ul{
    list-style: none;
}

.tiem{
    line-height: 2rem;
}

.btext-spc ul li{
    margin: 5px 0px;
}

.btext-spc ul li a{
    color: #525252;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.btext-spc ul li a:hover{
    border-bottom: 1px solid #525252;

}

.ligh{
    color:#888888;
}

.tryalps{
    padding: 15px 20px ;
    border: 1px solid #00000033;
    border-radius:25px ;
    color:#525252;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.bspc-img{
    height: 100%;
    width: 100%;
}
.alps{
    object-fit: fill;
    height: 100%;
    width: 100%;
}

@media screen and (max-width:768px) { 
    .seeBuild{
        padding: 50px;
        flex-direction: column-reverse;
    }
    .btext-spc{
        width: 100%;
    }

    .bspc-hd{
        width: 100%;
    }


}