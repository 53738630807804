.aboutme-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns: text and carousel */
    gap: 30px;
    padding: 2% 10%; /* Padding for larger screens */
}

.aboutme-text {
    font-family: Arial, sans-serif;
}

.aboutme-text h2 {
    font-size: 32px;
    margin-bottom: 20px;
}

.aboutme-text p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 15px;
}

.aboutme-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-large-image {
    margin-bottom: 20px; /* Space between enlarged image and thumbnails */
}

.enlarged-image {
    height: 300px; /* Set a fixed height for the enlarged image */
    object-fit: cover; /* Maintain aspect ratio without stretching */
    width: auto; /* Allow width to adjust based on height */
    border-radius: 8px; /* Optional: Rounded edges */
}

.carousel-thumbnails {
    display: flex;
    gap: 10px; /* Space between thumbnails */
}

.thumbnail {
    width: 80px; /* Fixed width for thumbnails */
    height: 60px; /* Fixed height for thumbnails */
    object-fit: cover; /* Maintain aspect ratio without stretching */
    border: 2px solid transparent; /* Border for inactive thumbnails */
    border-radius: 4px; /* Optional: Rounded edges */
    cursor: pointer; /* Change cursor on hover */
    transition: border-color 0.3s ease; /* Smooth border color transition */
}

.thumbnail.active {
    border-color: #3498db; /* Active border color */
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .aboutme-container {
        grid-template-columns: 1fr; /* Stack columns for mobile */
        padding: 5px 20px ; /* Adjust padding for smaller screens */
    }

    

    .aboutme-carousel {
        display: none;
    }

    .aboutme-text h2 {
        font-size: 24px; /* Smaller font size for heading */
    }

    .aboutme-text p {
        font-size: 16px; /* Smaller font size for paragraphs */
    }

    .enlarged-image {
        height: 200px; /* Adjust the height of the enlarged image for mobile */
    }

    .thumbnail {
        width: 60px; /* Smaller thumbnails for mobile */
        height: 45px; /* Adjust height accordingly */
    }
}
