.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.testimonial-tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.testimonial-tab {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.testimonial-tab.inactive {
  opacity: 0.5; /* Grey out inactive tabs */
  filter: grayscale(100%); /* Make the image black & white for inactive state */
}

.testimonial-tab.active {
  transform: scale(1.1); /* Highlight active tab */
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%; /* Circular frame */
  object-fit: cover;
  border: 2px solid #ccc;
  transition: border-color 0.3s ease;
}

.testimonial-tab.active .testimonial-image {
  border-color: #3498db; /* Active border color */
}

.testimonial-content {
  width: 30%; /* Adjust width for larger screens */
  text-align: justify;
  margin-top: 20px;
}

.testimonial-text {
  font-style: italic;
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.testimonial-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.testimonial-title {
  font-size: 16px;
  color: #888;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .testimonial-container {
    margin: 50px 20px;
  }
  .testimonial-tabs {
      flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
      justify-content: center; /* Center the tabs */
  }

  .testimonial-content {
      width: 100%; /* Full width for content on mobile */
      text-align: justify; /* Center align text on mobile */
      margin-top: 15px; /* Adjust margin for mobile */
  }

  .testimonial-text {
      font-size: 16px; /* Smaller font size for mobile */
  }

  .testimonial-name {
      font-size: 18px; /* Adjust name font size for mobile */
  }

  .testimonial-title {
      font-size: 14px; /* Adjust title font size for mobile */
  }

  .testimonial-image {
      width: 60px; /* Smaller image size for mobile */
      height: 60px; /* Maintain aspect ratio */
  }
}
