.cs-body{
    padding: 100px 100px;
}

.one-columns{
    width: 100%;
}
.two-columns{
    display: flex;
    gap: 50px;
    padding: 20px 0px;
    height: auto;
}

 .pk-imgt{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px 0px;
}
.one-columns{
    width: 100%;
}

.remv{
    width: 100%;
}

.one-long-columns{
    width: 87vw;
}

@media screen and (max-width:600px) {
    .cs-body{
        padding:100px  20px;
    }

    .two-columns{
        flex-direction: column;
    }

    /* .remv{
        display: none;
    } */

}

@media screen and (min-width:601px) and (max-width:1024px) {
    .cs-body{
        padding: 100px 50px;
    }
}






.cs-bd{
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}

.cardify-top{
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    padding-top: 100px;
}
  
.cardify{
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
}

.lofi{
    max-width: 100%;
    height: auto;
    object-fit: contain;
    height: 92vh;
}




@media screen and (max-width:600px) {
    .cs-bd{
        scroll-snap-type: y mandatory;
        overflow-y: scroll;
        height: 100vh;
        
    }

    .cardify-top{
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
        padding-top: 80px;
    }

    .cardify{
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
    }
    
    .lofi{
        max-width: 100%;
        height: auto;
        object-fit: contain;
        height: 100%;
    }

    
}

@media screen and (min-width:601px) and (max-width:1024px) {
    .cs-bd{
        scroll-snap-type: y mandatory;
        overflow-y: scroll;
        height: 100vh;
        
    }

    .cardify-top{
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
    }

    .cardify{
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: start;
    }
    
    .lofi{
        max-width: 100%;
        height: auto;
        object-fit: contain;
        height: 100%;
    }
}