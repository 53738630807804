.special{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 5px;
    padding: 20px;
    background-color: #F4F4F4;
    margin: 10px 0px;
}

.high{
    line-height: 1.9rem;
    color: #4B4B4B;
    font-size: 16px;
    letter-spacing: -0.389px;
}