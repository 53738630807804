.navi_gate {
  width: 100%;
  padding: 2% 20% 2% 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.desk_nav {
  display: flex;
  justify-content: space-between;
}
.Logo-nav {
  display: flex;
  align-items: center;
  gap: 20px;
  width: auto;
  box-shadow: 0px 0px 10px 0px #08080814;
  padding: 10px;
  border-radius: 8px;
  background: white;
}

.Logo-nav img {
  height: 35px;
  border-radius: 100%;
}

.img-navs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-link1,
.nav-link2 {
  display: flex;
  align-items: center;
  gap: 20px;
  width: auto;
}

.nav-link2 {
  box-shadow: 0px 0px 10px 0px #08080814;
  padding: 10px;
  border-radius: 8px;
  background: white;
}

.secondNav {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  padding: 15px;
}

.hid {
  display: none;
}

.nav-link1 a,
.nav-link2 a {
  color: #333333;
  text-decoration: none;
  font-weight: bold;
}

.secondNav a {
  color: #333333;
  text-decoration: none;
  font-weight: 600;
}
@media screen and (max-width: 480px) {
  .navi_gate {
    box-shadow: 0px 0px 10px 0px #08080814;
    padding: 0px;
    background: white;
    border-radius: 8px;
  }

  .nav-link1,
  .nav-link2 {
    display: none;
    box-shadow: none;
  }

  .Logo-nav {
    width: 100%;
    box-shadow: none;
  }

  .img-navs {
    width: 100%;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
  }

  .bars {
    width: 25px;
    height: 3px;
    background-color: #333;
    /* margin: 4px 0; */
    transition: 0.3s;
    border-radius: 10px;
  }

  .hid {
    display: none;
  }

  .cancel1 {
    position: absolute;
    transform: rotate(135deg);
  }

  .cancel2 {
    transform: translateX(100%);
    opacity: 0;
  }

  .cancel3 {
    transform: rotate(45deg);
    position: absolute;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .navi_gate {
    
  }
}
