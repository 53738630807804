.selected{
    background-color: white;
    padding: 2% 20% 2% 20%;
}

.sel-card{
    margin: 40px 0px;
}

.sel-head{
    display: flex;
}
.rg-ray{
    display: flex;
    align-items: center;
}

.sunray{
    margin-right:10px;
    transition: 2s;
    animation: rotate infinite ease-in-out 3s;
    
}

.rg-ray p{
    font-weight: 600;
}


/* animation to rotate the sunray */
@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

/*the list of selected projets section */

@media screen and (max-width:600px) {
    .selected{
        padding: 20px;
    }

    .sel-head{
        align-items: start;
    }
}


@media screen and (max-width:768px) {
    .selected{
        padding: 20px;
    }

}

@media screen and (min-width:769px) and (max-width:1024px) {
    .selected{
        padding:0px 50px;
    }


}