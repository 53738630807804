.hero-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 8% 20% 5% 20%;
  transition: color 0.8s ease;
}

/* Full width for hero content */
.hero-content {
  max-width: 100vw;
}

/* Large title for desktop */
.hero-title {
  font-size: 48px;
  margin: 0;
  font-weight: bold;
  color: #1c1c1c;
}

/* Subtitle styling */
.hero-subtitle {
  font-size: 36px;
  margin: 0;
  color: #4a4b4b;
}

/* Intro text */
.hero-intro {
  font-size: 18px;
  margin: 20px 0 10% 0;
  line-height: 1.6;
}

/* Line styling */
.line {
  border-bottom: 0.5px dashed #EDEDED;
}

.line.darkline {
  border-bottom: 0.5px dashed #313131;
}


.portfolio-section {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-decoration: none;
}
.portfolio-item a {
  color: inherit; /* Maintain the same color on hover */
  text-decoration: none;
}

.portfolio-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}
.a .hero-item {
  text-decoration: none;
}

.company-name {
  color: #4a4b4b
}

.project-info {
  color: #888;
}


/* Value section styling */
.value-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 2% 20% 5% 20%;
  transition: color 0.8s ease;
}

/* Core values container */
.core-values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

/* Header for core values */
.core-values-header {
  text-align: left;
}

.core-values-header h3 {
  font-size: 36px;
  margin: 0;
}

.core-values-header h3:first-of-type {
  color: #E1E1E1;
}

.core-values-header h3:last-of-type {
  color: #444444;
}

/* Core values content styling */
.core-values-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Individual core value styling */
.core-value {
  background-color: #1e1e1e;
  color: #E1E1E1;
  padding: 20px;
  border-radius: 8px;
  flex: 1;
  min-width: calc(50% - 20px); /* Two values per line on desktop */
}

/* Title within core value */
.core-value h4 {
  font-size: 24px;
  margin-top: 0;
}

/* Paragraph within core value */
.core-value p {
  font-size: 16px;
  margin: 10px 0 0;
}

/* Mobile responsiveness */
@media screen and (max-width:600px) {
  .hero-section{
      padding: 20% 20px;
  }

  .sel-head{
      align-items: start;
  }
}


@media screen and (max-width:768px) {
  .hero-section{
      padding: 20% 20px 0;
  }

}

@media screen and (min-width:769px) and (max-width:1024px) {
  .hero-section{
      padding: 10% 50px ;
  }


}