.skill-edu{
    display: flex;
    width: 65%;
    margin: 50px 0px;
    gap: 0px 50px;
}

.back-bg{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.text-wrapper{
    width: 50%;
    text-align: right;
    color: #464646;
    line-height: 1.7rem;
}

.box-es{
    background-color: #F7F7F9;
    border: 1px solid #EBEBEB;  
    padding: 20px;
    border-radius: 20px;
}

.bxp-1{
    display: flex;
    justify-content: space-between;
    line-height: 2rem;
}

.gry a {
        color: #525252;
}

.skl-box{
    display: flex;
    justify-content: space-between;
    gap:20px;
    background-color: #F7F7F9;
    border: 1px solid #EBEBEB;
    padding: 20px;
    border-radius: 25px;
}

.smc{  
    list-style: none;
    line-height: 1.9rem;
}



.smc p{
    font-weight: bold;
    font-size: 16px;
}

.smc li{
    font-size: 14px;
    color: #888888;

}

.smc li a{
    color: #525252;
}

@media screen and (max-width:600px) {
    .skill-edu{
        flex-direction: column;
        width: 100%;
        padding:0px 20px;
    }

    .text-wrapper{
        width: 100%;
        text-align: left;
        line-height: 3rem;
    }

    .smc li a, .smc li{
        font-size: 14px;
    }

    .skl-box{
        flex-direction: column;
    }
}

@media screen and (min-width:601px) and (max-width:1024px) {
    .skill-edu{
        width: 80%;
    }
}