.p-card {
    align-items: center; /* Center items vertically within the container */
    justify-content: space-between; 
    background-color: #ffffff; /* Default background color for light theme */
    text-decoration: none; 
    margin: 20px 0;
  }
  
  .p-card.darkbgli {
    background-color: #1e1e1e; /* Dark background for dark theme */
  }
  
  
  .image-cont {
    display: block;
    width: 100%;
    border-radius: 16px;
    object-position: center center;
    object-fit: cover;
    image-rendering: auto;
  }
  
  .image-cont-img:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  .image-cont-img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-position: center center;
    object-fit: cover;
    image-rendering: auto;
    transition: transform 0.3s ease;
  }
  
  .p-name {
    font-size: 1.2rem; /* Font size for titles */
    margin: 20px 0 8px 0;
    color: #333; /* Default title color */
  }
  
  .title {
    color: #1E1E1E;
    font-size: 1.6  rem;
    margin: 0 0 8px 0;
  }

  .title.darkHead {
    color: #E1E1E1; /* Title color for dark theme */
  }
  
  .role, .brief {
    font-size: 1.2rem; /* Font size for genre and brief */
    margin: 5px 0; /* Margin between text blocks */
    color: #666; /* Default text color */
  }
  
  .role.darkText, .brief.darkText {
    color: #ccc; 
  }
  /* Responsive adjustments for mobile */
  @media (max-width: 768px) {
    .select-list {
      flex-direction: column; /* Stack text and image vertically on smaller screens */
    }
  
    .text-side {
      padding-right: 0; /* Remove right padding on smaller screens */
    }
  
    .image-side {
      margin-top: 20px; /* Space between text and image on smaller screens */
    }
  }
  