.casedefault{
}

.bigBanner{
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius:25px ;
}

.bigImage{
    height: 100%;
    width:100%;
    object-fit: contain;
    object-position: center;

}

.nameStyle{
    padding: 20px 0px;
    color:#464646;
}

.nameStyle h1{
    font-size: 36px;
}

.nameStyle p{
    color: #353535;
    font-weight: 600;
    font-size: 18px;
    line-height: 3rem;
}

.dif-info{
    display: flex;
    gap: 20px;
    padding: 20px 0px;
}

.def2 h3{
    line-height: 3rem;
    color: #525252;
    letter-spacing: -0.389px;

}

.def2 p, li{
    line-height: 1.7rem;
    color: #4B4B4B;
    font-size: 16px;
    letter-spacing: -0.389px;

}

.def2{
    width: 100%;
}

.pr-aag{
    margin-bottom:20px ;
    line-height: 1.7rem;
    width: 100%;
}

.sin-g{
    margin: 50px 0px;
}

@media screen and (max-width:600px) {
    .dif-info{
        flex-direction: column;
    }
}