.abt-pg{
    padding: 20px 0px;
} 
.a-banner{
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;
    position: relative;
}

.a-banner p{
    display: flex;
    align-items: center;
    font-size: 64px;
    font-weight: 600;
    color: #2B2B2B;
} 

.shine{
    height: 70px;
    transition: 2s;
    animation: rotate infinite linear 3s;
}

.cir{
    height: 150px;
    width: 150px;
    border-radius: 100%;
    background-color: #DEDEDE;
    position: absolute;
    bottom: -70px;
    overflow: hidden;
}

.cir-image{
    object-fit: cover;
    object-position: right  ;
    height: 100%;
    width: 100%;
}
/* animation to rotate the sunray */
@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

.box-ed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 100px 0px;
    padding: 50px 0px;
}

@media screen and (max-width:600px){
    .a-banner p{
        font-size: 32px;
    }
}
