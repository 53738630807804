
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing:auto;
  cursor: url(../src/Assets/images/pointer.png), auto;
}

.app{
  width: 100%;
  position: relative;

}

p,h1,h2,h3,h4,h5,h6{
  /* cursor: url(../src/Assets/images/cursor-text.png), text; */
}

.global-button{
  padding: 10px 15px;
  border-radius: 10px;
  border:1px solid #DEDEDE;
  background-color: black ;
  color: white;
  font-weight: bold;
  cursor: pointer;
}


.white{
  padding: 10px;
  border-radius: 10px;
  border:1px solid #C6C6C6;
  background-color: transparent ;
  color: black;
  margin-left: 20px;
  cursor: pointer;
}

.darkText{
  color: #e1e1e1 !important;
}

.darkText p{
  color: #e1e1e1 !important;
}

.darkHead{
  color: #9c9c9c !important;
}

.darkbg{
  background-color:#121212 !important;
  color: white !important;
}

.darkbox{
  background-color:rgba(128, 128, 128, 0.222)  !important;
  border: none !important;
}

.darkbox .gry a, .darkbox .gry{
  color: #e1e1e1 !important;

}

.darkbox li, .darkbox li a{
  color: #e1e1e1 !important;

}


.darkbgli{
  background-color: #c6c6c612 !important;
}

/**special case **/
.darkText ul li a{
  color: #e1e1e1 !important;
}

.darkButton{
  background-color:#e1e1e1 !important;
  color: black !important;
}