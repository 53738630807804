.caseheads{
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-top: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    margin: 20px 0px;

}

.caseheads p{
    margin-left: 10px;
    font-size: 18px ;
    color: #4B4B4B;
    font-weight: 500;
}

.star{
    width: 20px;
    height: 20px;
}